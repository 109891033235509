.Header {
  display: flex;
  width: 100vw;
  max-width: 100vw;
  height: 3vw;
  font-weight: 500;
  text-align: center;
  font-family: "EB Garamond", serif;
  color: white;
  background-color: #ea82b7;
  align-items: center;
  justify-content: center;
  padding: 0%;
  margin: 0%;
}

.button-38 {
  height: 100%;
  max-height: 30px;
  background-color: #ffffff;
  border: 0;
  border-radius: 0.5rem;
  box-sizing: border-box;
  color: #111827;
  font-family: "EB Garamond", serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  padding: 0.75rem 1rem;
  text-align: center;
  text-decoration: none #d1d5db solid;
  text-decoration-thickness: auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 1.5%;
  margin-right: 1.5%;
}

.button-38:hover {
  background-color: rgb(249, 250, 251);
}

.button-38:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.button-38:focus-visible {
  box-shadow: none;
}

.Button {
  margin-right: 3%;
}

.container {
  background: red;
  max-width: 0px;
  padding-left: 0%;
  margin-left: 0%;
}
