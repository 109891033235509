// body {
//   align-items: center;
//   background: #e3e3e3;
//   display: flex;
//   height: 100vh;
//   justify-content: center;
// }

@mixin white-gradient {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

$animationSpeed: 40s;

// Animation
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

// Styling
.slider {
  background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 150px;
  margin: auto;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  width: 100vw;

  &::before,
  &::after {
    @include white-gradient;
    content: "";
    height: 150px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  .slide-track {
    animation: scroll $animationSpeed linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slide {
    height: 150px;
    width: 250px;
  }
}
