.hero-full-screen {
  height: 45vh;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: url("../../Assets/logo3.png") center center no-repeat;
  background-color: #ea82b870;
  background-size: 100vh;
  margin-bottom: 15px;
}

.hero-full-screen .middle-content-section {
  text-align: center;
  color: yellow;
}

.hero-full-screen .top-content-section {
  width: 100%;
}

.hero-full-screen .bottom-content-section {
  padding: 1rem;
}

.hero-full-screen .bottom-content-section svg {
  height: 1.5rem;
  width: 1.5rem;
  fill: white;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: none;
  padding-left: none;
  margin-right: none;
  margin-left: none;
}
